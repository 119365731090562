<template>
<footer>
  <div id="main-footer" class="footer">
    <div class="footer_logo"><a href="/login/top/"><img src="/assets/images/common/m-logo.png" alt="Majesty"> </a> </div>
    <ul class="left">
      <li><a href="/terms/" target="_blank">プライバシーポリシー</a></li>
    </ul>
    <ul class="right">
      <li><a href="/login/faq/">よくあるご質問</a><span v-html="loggedInMenu"></span></li>
    </ul>
  </div>
  <div class="copyright">
    <p>&copy; Majesty Golf 2020</p>
  </div>
</footer>
</template>

<script>
import Axios from 'axios'

export default {
  data: function () {
    return {
      loggedInMenu: ''
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init: function () {
      Axios.get('/api/logged_in_footer_menu')
      .then((res) => {
        this.$set(this.$data, 'loggedInMenu', res.data)
      })
    }
  }
}
</script>
