<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    processInit: function () {
      jQuery(document).ready(function ($) {
        if (window.matchMedia('(min-width: 768px)').matches) {
          //画面幅768px以上の場合
          var headerHight = 75;
          $('a[href^="#"]').click(function () {
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top - headerHight;
            $("html, body").animate({
              scrollTop: position
            }, 550, "swing");
            return false;
          });
        } else {
          //画面幅767px以下の場合
          var headerHight = 45;
          $('a[href^="#"]').click(function () {
            var href = $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top - headerHight;
            $("html, body").animate({
              scrollTop: position
            }, 550, "swing");
            return false;
          });
        };
      });

      this.processSlider()
    },
    processMenu: function () {
      $('.menu').click(function() {
        $('.menuWrap').fadeToggle(500);
      });

      $('.h_login ul li').click(function() {
        $('.h_menu-sub').fadeToggle(200);
      });
    },
    processLeftMenu: function () {
      $('.close').click(function() {
        $('.menuWrap').fadeToggle(500);
      });

      $('.menuWrap a').click(function() {
        $('.menuWrap').fadeToggle(500);
      });
    },
    processSlider: function () {
      document.addEventListener('DOMContentLoaded', function(){
        // タブに対してクリックイベントを適用
        const tabs = document.getElementsByClassName('tab');
        for(let i = 0; i < tabs.length; i++) {
          tabs[i].addEventListener('click', tabSwitch);
        }

        // タブをクリックすると実行する関数
        function tabSwitch(){
          // タブのclassの値を変更
          document.getElementsByClassName('is-active')[0].classList.remove('is-active');
          this.classList.add('is-active');
          // コンテンツのclassの値を変更
          document.getElementsByClassName('is-show')[0].classList.remove('is-show');
          const arrayTabs = Array.prototype.slice.call(tabs);
          const index = arrayTabs.indexOf(this);
          document.getElementsByClassName('panel')[index].classList.add('is-show');
        };
      });

      jQuery(function($){
        $('.slider').each(function(){
          $(this).slick({
            autoplay:true,
            autoplaySpeed:5000,
            dots:false,
            arrows:false
          });
        });	
      });
    },
    processToggleActive: function () {
      $(".toggle dt").on("click", function() {
        $(this).next().slideToggle()
        $(this).toggleClass("active")
      })
    },
    initModalOpen: function () {
      // 変数に要素を入れる
      const open = $('.modal-open')
      const close = $('.modal-close')
      const container = $('.modal-container')

      //開くボタンをクリックしたらモーダルを表示する
      open.on('click', function() {
        container.addClass('active')
        return false
      })

      //閉じるボタンをクリックしたらモーダルを閉じる
      close.on('click', function() {
        container.removeClass('active')
      });

      //モーダルの外側をクリックしたらモーダルを閉じる
      $(document).on('click', function(e) {
        if (!$(e.target).closest('.modal-body').length) {
          container.removeClass('active')
        }
      })
    },
    initMoreText: function () {
      $(".more").on("click", function() {
        $(this).toggleClass("on-click")
        $(".txt-hide").slideToggle(500)
      })
    },
    moreText: function () {
      $("#more-text").toggleClass("on-click")
      $(".txt-hide").slideToggle(500)
    }
  }
}
</script>
