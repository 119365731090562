import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from '../../components/top/owners_club_lp.vue'

document.addEventListener('DOMContentLoaded', () => {
  const el = '#app'
  const app = new Vue({
    el,
    render: h => h(App)
  })
})
