<template>
<div>
<span v-if="mediaQueries" />
<div v-html="ownerMenu">
</div>
</div>
</template>

<script>
import Axios from 'axios'
import Common from './common'
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    sp: 767,
    pc: Infinity
  }
})

export default {
  data: function () {
    return {
      logged_in: false,
      ownerMenu: '<header><div class="container"><div class="logo"><a href="/"><img src="/assets/images/common/m-logo.png" alt="Majesty"></a></div><div class="h_login"><ul></ul></div></div></header>'
    }
  },
  beforeMount: function () {
    this.reload()
  },
  mixins: [Common],
  methods: {
    reload: function () {
      //this.$root.$children[0].$set(this.$root.$children[0].$data, 'isLoading', true)

      Axios.get('/api/owner_menu')
        .then((res) => {
          let prevOwnerMenu = this.ownerMenu
          this.$set(this.$data, 'ownerMenu', res.data)

          if (res.data != prevOwnerMenu) {
            let processMenuMounted = (elm) => {
              elm.processLeftMenu()
              elm.processMenu()
              elm.processToggleActive()
            }

            setTimeout(processMenuMounted, 1000, this)
          }

          if (this.$mq === 'pc') {
            setTimeout(() => {
              $('#menu-name02').show()
            }, 1)
          }
          else {
            setTimeout(() => {
              $('#menu-name02').hide()
            }, 1)
          }

          //this.$root.$children[0].$set(this.$root.$children[0].$data, 'isLoading', false)
        }
      )
    }
  },
  computed: {
    mediaQueries: function () {
      if (this.$mq === 'pc') {
        $('#menu-name02').show()
      }
      else {
        $('#menu-name02').hide()
      }

      return this.$mq === 'sp'
    }
  }
}
</script>

<style>
#menu-name02 {
  display:none;
}
</style>
